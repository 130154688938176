// src/components/Footer.jsx
import React from 'react';
import '../assets/styles/Footer.css';

function Footer() {
    return (
        <footer className='footer'>
            <p>&copy; 2024 Leonardo Scappatura</p>
        </footer>
    );
}

export default Footer;