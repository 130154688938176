import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../assets/styles/Header.css';

function Header() {
    const [activeLink, setActiveLink] = useState('/');
    const location = useLocation();

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location]);

    return (
        <header className="header">
            <nav className="nav-links">
                <Link to="/" className={activeLink === '/' ? 'active' : ''} >
                    home
                </Link>

            </nav>
        </header>
    );
}

export default Header;